<template>
  <div class="relative bg-white shadow">
    <div class="mx-auto px-4 sm:px-6">
      <div class="flex justify-between items-center py-4 lg:space-x-10">
        <div class="flex justify-start lg:w-0 lg:flex-1 space-x-8">
          <a :href="route('homepage')">
            <span class="sr-only">Street Insights</span>
            <ApplicationLogo class="block h-10 w-auto fill-current text-gray-600" />
          </a>

          <div class="hidden sm:-my-px sm:ml-10 lg:flex space-x-8">
            <NavLink
              :url="route('homepage')"
              :active="route().current('homepage')"
              title="Home"
            />
            <NavLink
              :url="route('pricing')"
              :active="route().current('pricing')"
              title="Pricing"
            />
          </div>
        </div>

        <div class="hidden lg:flex items-center justify-end lg:flex-1 lg:w-0">
          <InsightsButton
            color="transparent"
            :href="route('login')"
            class="text-base font-medium"
          >
            Sign in
          </InsightsButton>
          <InsightsButton
            color="primary"
            :href="route('register')"
            class="text-base font-medium"
          >
            Sign up
          </InsightsButton>
        </div>

        <!-- Hamburger -->
        <div class="-mr-2 flex items-center lg:hidden">
          <button
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
            @click="open = !open"
          >
            <span class="sr-only">Open Menu</span>
            <svg
              class="h-6 w-6"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                :class="{'hidden': open, 'inline-flex': ! open }"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
              <path
                :class="{'hidden': ! open, 'inline-flex': open }"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>

      <!-- Responsive Navigation Menu -->
      <div
        :class="{'block': open, 'hidden': !open}"
        class="lg:hidden"
      >
        <div class="flex flex-col pt-2 pb-3 space-y-4">
          <NavLink
            :url="route('homepage')"
            :active="route().current('dashboard')"
            title="Home"
          />
          <NavLink
            :url="route('pricing')"
            :active="route().current('pricing')"
            title="Pricing"
          />
        </div>

        <!-- Responsive Settings Options -->
        <div class="flex flex-col pt-4 pb-4 space-y-2">
          <InsightsButton
            class="justify-center"
            :href="route('register')"
          >
            Sign Up
          </InsightsButton>
          <InsightsButton
            class="justify-center"
            color="gray-outline"
            :href="route('login')"
          >
            Log in
          </InsightsButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import NavLink from '@/Components/Navigation/NavLink.vue';
import InsightsButton from '@/Components/Forms/InsightsButton.vue';
import { ref } from 'vue';

const open = ref(false);
</script>

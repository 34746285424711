<template>
  <div class="py-8">
    <div class="flex flex-col items-center space-y-4 px-4 md:space-y-0 md:justify-between md:flex-row lg:px-20">
      <span class="text-sm text-gray-500">&copy; {{ new Date().getFullYear() }} Street Group. All rights reserved.</span>
      <div class="flex space-x-8">
        <a
          class="text-sm text-gray-500"
          :href="`${route('policies')}#privacy-policy-`"
        >Privacy</a>
        <a
          class="text-sm text-gray-500"
          :href="`${route('policies')}#terms-and-conditions-`"
        >Terms and Conditions</a>
      </div>
    </div>
  </div>
</template>
